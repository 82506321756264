/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  StudioFlowEdge,
  StudioFlowNode,
  StudioNodeData,
} from '@common/studio-types';
import { useAbly } from 'ably/react';
import { useEffect } from 'react';

type Args = {
  episodeId: string;
  onNodeChange: (node: StudioNodeData[]) => void;
  onNodeCreated: (node: StudioFlowNode[]) => void;
  onEdgeCreated: (node: StudioFlowEdge[]) => void;
};

export const useRealtimeStudioFlow = (args: Args) => {
  const { episodeId, onNodeChange, onNodeCreated, onEdgeCreated } = args;
  const realtime = useAbly();

  useEffect(() => {
    const channel = realtime.channels.get(`studioFlow:${episodeId}`);

    channel.subscribe((evt: any) => {
      // eslint-disable-next-line no-console
      console.log(evt);

      switch (evt.name) {
        case 'UpdateNode':
          return onNodeChange([evt.data as StudioNodeData]);
        case 'NewNode':
          return onNodeCreated([evt.data as StudioFlowNode]);
        case 'NewEdge':
          return onEdgeCreated([evt.data as StudioFlowEdge]);
      }
    });

    setTimeout(() => {
      channel.history().then((history: any) => {
        if (!!history.items?.length) {
          const updateNodes: StudioNodeData[] = history.items
            .filter(({ name }: any) => name === 'UpdateNode')
            .map(({ data }: any) => data);
          onNodeChange(updateNodes);

          const newNodes: StudioFlowNode[] = history.items
            .filter(({ name }: any) => name === 'NewNode')
            .map(({ data }: any) => data);
          onNodeCreated(newNodes);

          const newEdges: StudioFlowEdge[] = history.items
            .filter(({ name }: any) => name === 'NewEdge')
            .map(({ data }: any) => data);
          onEdgeCreated(newEdges);
        }
      });
      // we should wait 1 second before we get the history to make sure we'll be able to pull
      // all messages that were not processed and reduce risk of race-conditions
    }, 2000);

    return () => channel.unsubscribe();
  }, [realtime]);
};
