import { Button, ButtonGroup, Portal } from '@chakra-ui/react';
import {
  ImageInput,
  TextInput,
  FormControl,
  Textarea,
  useMaestroToast,
  Loader,
} from '@maestro/components';
import { Select } from '@maestro/components/Select';
import {
  useCreateSeriesSatelliteDataQuery,
  useMaestroSeriesQuery,
  useUpdateSeriesMutation,
} from '@maestro/graphql';
import { breakpoints, dimensions } from '@maestro/styles';
import { useQueryClient } from '@tanstack/react-query';
import { FC, MutableRefObject, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStudioFlowStore } from '../../../hooks/useStudioFlowStore';

type Props = {
  onComplete?: () => void;
  seriesId: string;
  buttonsParentRef: MutableRefObject<HTMLElement | null>;
};

type Fields = Partial<{
  coverImageUrl: string;
  title: string;
  genreId: string;
  description: string;
}>;

export const EditSeriesForm: FC<Props> = ({
  buttonsParentRef,
  seriesId,
  onComplete,
}) => {
  const [series, setSeries] = useState<Fields>({
    coverImageUrl: '',
    title: '',
    genreId: '',
    description: '',
  });
  const [submitPressed, setSubmitPressed] = useState(false);
  const { data: seriesSourceData, isLoading: isLoadingSeriesData } =
    useMaestroSeriesQuery(
      {
        input: {
          seriesId,
        },
      },
      {
        cacheTime: 0,
        staleTime: 0,
        retry: false,
      },
    );
  const { data: satelliteData } = useCreateSeriesSatelliteDataQuery();
  const queryClient = useQueryClient();
  const { mutateAsync: updateSeries, isLoading: isUpdatingSeries } =
    useUpdateSeriesMutation();
  const toast = useMaestroToast();
  // NOTE: I hate doing this here, but it narrows down the update, as a follow up we need
  // to just fetch the series detaisl specific to maestro
  const { updateSeriesTitle } = useStudioFlowStore((store) => ({
    updateSeriesTitle: store.setSeriesTitle,
  }));

  useEffect(() => {
    if (!!seriesSourceData?.maestroSeries?.series) {
      const { series } = seriesSourceData?.maestroSeries;
      setSeries({
        coverImageUrl: series?.media?.main ?? '',
        title: series?.title === 'Untitled series' ? '' : (series?.title ?? ''),
        genreId: series?.genreId ?? '',
        description:
          series?.description === 'Series description here'
            ? ''
            : (series?.description ?? ''),
      });
    }
  }, [seriesSourceData]);

  const onChange = (key: keyof Fields, value?: string) => {
    setSeries((state) => ({ ...state, [key]: value }));
  };

  const onSubmit = async () => {
    if (isUpdatingSeries) {
      return;
    }

    if (!series.genreId || !series.title) {
      setSubmitPressed(true);

      return;
    }

    try {
      const updateResponse = await updateSeries({
        input: {
          id: seriesId,
          ...series,
        },
      });

      if (updateResponse?.updateSeries?.error) {
        throw Error('Failed to save series details, api replied with an error');
      }

      toast({
        title: 'Series updated!',
        status: 'success',
      });

      await queryClient.invalidateQueries(['maestroSeries']);

      updateSeriesTitle(series.title);
      onComplete?.();
    } catch (error: unknown) {
      // TODO: add sentry or logrocket exception handler here
      // eslint-disable-next-line no-console
      console.error(error);
      toast({
        title: 'Oops! it was not possible to save your changes',
        status: 'warning',
      });
    }
  };

  return (
    <>
      {isLoadingSeriesData && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      <SplitView>
        <Column>
          <FormControl
            label="Series cover image"
            noMargin
            formControlProps={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'flex-start',
              height: '100%',
            }}
            errorMessage="Add a series cover image"
          >
            <ImageInput
              value={series.coverImageUrl}
              isInvalid={submitPressed && !series.coverImageUrl}
              uploadPath="series"
              onChange={(image) => onChange('coverImageUrl', image?.path)}
              insideFlexContainer
              containerAspectRatio={{
                width: dimensions.size200,
                height: dimensions.size300,
              }}
            />
          </FormControl>
        </Column>
        <Column>
          <FormControl
            label="Title"
            isEnabled={submitPressed}
            object={series}
            field="title"
            errorMessage="Write a series title"
          >
            <TextInput
              maxCharacters={50}
              value={series.title}
              onChange={(e) => onChange('title', e.target.value)}
              placeholder="Untitled series"
            />
          </FormControl>
          <FormControl
            label="Genre"
            isEnabled={submitPressed}
            object={series}
            field="genreId"
            errorMessage="Select a genre"
          >
            <Select
              value={series.genreId}
              onChange={(nextValue) => onChange('genreId', nextValue)}
              placeholder="Select a genre"
              options={
                satelliteData?.createSeriesSatelliteData?.genres?.map(
                  (option) => ({
                    label: option.label,
                    value: option.id,
                  }),
                ) ?? []
              }
            />
          </FormControl>
          <FormControl
            label="Overview"
            noMargin
            formControlProps={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'flex-start',
              height: '100%',
            }}
            errorMessage="Write a series overview"
          >
            <Textarea
              maxCharacters={1000}
              value={series.description}
              onChange={(e) => onChange('description', e.target.value)}
              placeholder="Write a short story description for your series"
            />
          </FormControl>
        </Column>
      </SplitView>
      <Portal containerRef={buttonsParentRef}>
        <ButtonGroup
          display={'flex'}
          flexFlow={'row'}
          justifyContent={'flex-end'}
        >
          <Button
            onClick={onSubmit}
            variant="primary"
            size="md"
            isDisabled={isUpdatingSeries}
            isLoading={isUpdatingSeries}
          >
            Save
          </Button>
        </ButtonGroup>
      </Portal>
    </>
  );
};

const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size24};
  align-items: flex-start;
  width: 100%;

  @media ${breakpoints.tablet} {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
  width: calc(50% - ${dimensions.size12});
  min-width: calc(50% - ${dimensions.size12});
  max-width: calc(50% - ${dimensions.size12});

  @media ${breakpoints.tablet} {
    flex: 1;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};
`;
